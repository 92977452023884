import './App.css';
import {Index} from "./pages";
import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main: '#14956E',
    },
    white:{
      main:'#FFF'
    },

    transparent:{
      main:'transparent'
    },
    secondary: {
      main: '#070510',
    },
    third:{
      main:'#151B2B'
    },
    lightGreen: {
      main: '#0EF1AD'
    },
    Green:{
      main: '#00FF85'
    }
  },
});
function App() {
  return (
      <ThemeProvider theme={theme}>
        <Index />
      </ThemeProvider>
  );
}

export default App;
