// @flow
import * as React from 'react';
import {Navbar} from "../components/navbar";
import {Top} from "../components/top";
import {Service} from "../components/service";
import {Mission} from "../components/mission";
import {Faq} from "../components/faq";
import {Membership} from "../components/membership";
import {Subscribe} from "../components/subscribe";
import {Footer} from "../components/footer";
import {Contact} from "../components/contact";


export function Index() {
    return (
        <div className="Index">
            <Navbar/>
            <Top/>
            <Service/>
            <Mission/>
            <Faq/>
            <Membership/>
            <Contact/>
            <Subscribe/>
            <Footer/>
        </div>
    );
};