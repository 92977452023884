// @flow
import * as React from 'react';


export function Subscribe() {
    return (
        <div className="Subscribe">
            <div className="signup__newletter">
                <h3 className="newletter__title">Sign up for our news letter</h3>
                <div className="jonNow">
                    Join now with WiseWager to get the latest <br/>news and start betting today.
                </div>
            </div>
            <div className="subscribe">
                <input type="text" placeholder="Enter your email"/>
                <button className="subscribe__btn">Subscribe</button>
            </div>
        </div>
    );
};