// @flow
import * as React from 'react';
import { useEffect, useRef } from 'react';

import logo from '../assets/img/logo.svg';
import Box from '@mui/material/Box';
import menu from '../assets/img/menu.png';
import '../styles/navbar.css';
export function Navbar() {
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      setAnchorEl((prevAnchorEl) => !prevAnchorEl);
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  // const handleClick = (e) => setAnchorEl((prevAnchorEl) => !prevAnchorEl);

  return (
    <div style={{ width: '100%' }}>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} className='navbar'>
        <img src={logo} alt='logo' />

        <Box
          sx={{ display: { xs: 'none', md: 'flex' }, gap: '88px' }}
          className='items'
        >
          <a href='#service'>
            <span>Services</span>
          </a>
          <a href='#ourMission'>
            <span>Our Mission</span>
          </a>
          <a href='#Testimonials'>
            <span>Testimonials</span>
          </a>
          <a href='#Contact'>
            <span>Contact Us</span>
          </a>
        </Box>
        <Box
          sx={{ display: { md: 'flex' }, gap: '38px' }}
          className={'RightBtns'}
        >
          <button color='secondary' className={'login'} type={'button'}>
            Login
          </button>
          <button color='secondary' className={'signup'}>
            SIGN UP
          </button>
        </Box>
      </Box>
      <div className='mobile-navbar' ref={ref}>
        <div className='menuBar'>
          {/* <div className='menuBar' onClick={handleClick}> */}
          <img src={menu} alt='menu' />
        </div>
        {anchorEl ? (
          <div className='mobile_menu'>
            <a href='#service'>
              {/* <a href='#service' onClick={handleClick}> */}
              <span>Services</span>
            </a>
            <a href='#ourMission'>
              {/* <a href='#ourMission' onClick={handleClick}> */}
              <span>Our Mission</span>
            </a>
            <a href='#Testimonials'>
              {/* <a href='#Testimonials' onClick={handleClick}> */}
              <span>Testimonials</span>
            </a>
            <a href='#Contact'>
              {/* <a href='#Contact' onClick={handleClick}> */}
              <span>Contact Us</span>
            </a>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
