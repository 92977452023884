// @flow
import * as React from 'react';
import test from '../assets/img/group.png';
import SportsIcon from '../assets/img/Sports icon.png';
import people from '../assets/img/people.png';
import guide from '../assets/img/template-get-started-icon 1.png';
import machine from '../assets/img/get-started-icon-frame.png';
import startWin from '../assets/img/get-started-icon-frame (1).png';

export function Service() {
    return (
        <div className="Service" id="service">
            <div className="service__title"> Services </div>
            <div className="sportsIcon">
                <img src={test} alt=""/>
            </div>
            <div className="description">
                <img src={SportsIcon} alt="SportsIcon"/>
                <span className="des__title">
                    The average bettor researches a hand full of data points when making decisions. Our prediction model researches over hundreds of data points from each sport, down to the individual player level.
                </span>
            </div>
            <div className="BottomPart">
                <div className="Guide__machine__start">
                    <div className="guide__support">
                        <img src={guide} alt="people"/>
                        <div className="guide_description">
                            <div className="guide__title">Guides and Support</div>
                            <div className="guide__subtitle">
                                Our team provides in depth support and <br/>guides for begginers and advanced <br/>betters
                            </div>
                        </div>
                    </div>
                    <div className="machine__learning">
                        <img src={machine} alt="people"/>
                        <div className="guide_description">
                            <div className="guide__title">Machine Learning</div>
                            <div className="guide__subtitle">
                                We use machine learning to strategacilly place bets
                            </div>
                        </div>
                    </div>
                    <div className="start__wining__today">
                        <img src={startWin} alt="people"/>
                        <div className="guide_description">
                            <div className="guide__title">Start Winning today</div>
                            <div className="guide__subtitle">
                                What are you waiting for?
                            </div>
                        </div>
                    </div>
                </div>
                <img src={people} alt="people"/>
            </div>

        </div>
    );
};