// @flow
import * as React from 'react';
import logo from '../assets/img/wisewager 1.png';

export function Top() {
    return (
        <div className="top">
            <div className="title" >AI SPORTS BETTING</div>
            <div className="subtitle" >A science-based approach to sports betting</div>
            <img src={logo} alt="top-logo" className="topLogo"/>
            <div className="Join">
                <button className="join">Join Now</button>
            </div>
        </div>
    );
};