// @flow
import * as React from 'react';
import firstCircle from '../assets/img/1 circle.png';
import secondCircle from '../assets/img/2 circle.png';
import thirdCircle from '../assets/img/3 circle.png';
export function Mission() {
    return (
        <div className="ourMission" id="ourMission">
            <div className="MissionTitle">
                Our Mission
            </div>
            <div className="Mission__content">
                <div className="first">
                    <img src={firstCircle} alt=""/>
                    <div className="mission__description">To become the ultimate source of data to help bettors<br/> win!
                    </div>
                </div>
                <div className="second">
                    <img src={secondCircle} alt=""/>
                    <div className="mission__description">
                        We push out easy-to-read guides to tell you what is <br/>favored to win, cover the spread, and go over/under <br/>based on our machine-learning models.

                    </div>

                </div>
                <div className="third">
                    <img src={thirdCircle} alt=""/>
                    <div className="mission__description">
                        Our machine-learning prediction model uses historical <br/>data that dates back 5+ years and updates weekly to<br/> get a better understanding of how games and players<br/> are performing NOW!
                    </div>

                </div>
            </div>
        </div>
    );
};