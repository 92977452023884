// @flow
import * as React from 'react';

export const Membership = () => {
    return (
        <div className="membership">
            <div className="membershipBorder">
                <div className="membership__title">
                    WiseWager<br/>
                    Membership
                </div>
                <div className="pay__perMonth">
                    <span className="price">$19.00</span>
                    <span className="month">/ month</span>
                </div>
                <div className="divider"/>
                <div className="member__description">
                    <div className="firstDescription">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="white"/>
                            <path d="M5 8.94545L7.84444 12L13 6" stroke="#3F3F3F" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="fullAccess">Full access to every prediction model</span>
                    </div>
                    <div className="secondDescription">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="white"/>
                            <path d="M5 8.94545L7.84444 12L13 6" stroke="#3F3F3F" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="fullAccess"><span style={{color:"white"}}>FREE</span> props.cash discord bot </span>
                    </div>
                    <div className="thirdDescription">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="white"/>
                            <path d="M5 8.94545L7.84444 12L13 6" stroke="#3F3F3F" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="fullAccess"> Chat room access with other WiseWager members</span>
                    </div>
                </div>
                <button className="choosePlan">
                    <span>Choose this plan</span>
                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.0303 7.03033C15.3232 6.73744 15.3232 6.26256 15.0303 5.96967L10.2574 1.1967C9.96447 0.903806 9.48959 0.903806 9.1967 1.1967C8.90381 1.48959 8.90381 1.96447 9.1967 2.25736L13.4393 6.5L9.1967 10.7426C8.90381 11.0355 8.90381 11.5104 9.1967 11.8033C9.48959 12.0962 9.96447 12.0962 10.2574 11.8033L15.0303 7.03033ZM0.5 7.25H14.5V5.75H0.5V7.25Z" fill="white"/>
                    </svg>

                    </button>
            </div>
        </div>
    );
};