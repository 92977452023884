// @flow
import * as React from 'react';
import {useState} from "react";

export function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const sendMessage =()=>{
        console.log('send message');
        console.log(name, email, message);
        setTimeout(()=>{
            setName('');
            setEmail('');
            setMessage('');
        },500)
    };
    return (
        <div className="Contacts" id="Contact">
            <div className="contact">
                <div className="contact__title">Contact</div>
                <div className="contact__body">
                    <div className="contactBody">
                        <div className="contact__left">
                            <div className="question">For questions and Inquires</div>
                            <div className="questionDes">
                                For any questions and general inquires please fill out the contact form to get in touch.
                            </div>
                        </div>
                        <div className="contact__right">
                            <div className="question">Ready to Get started?</div>
                            <div className="contact__form">
                                <input type="text" placeholder="Name" className="name" value={name} onChange={event => setName(event.target.value)}/>
                                <input type="text" placeholder="Email" className="name" value={email} onChange={event => setEmail(event.target.value)}/>
                                <textarea name="message" id="message" placeholder="your message" value={message} onChange={(e)=>setMessage(e.target.value)}/>
                                <button type={'button'} className="sendMessageBtn" onClick={sendMessage}>
                                    <span>Send message</span>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.5 2.15869C6.90811 2.15869 2.375 6.6918 2.375 12.2837C2.375 17.8756 6.90811 22.4087 12.5 22.4087C18.0919 22.4087 22.625 17.8756 22.625 12.2837C22.625 6.6918 18.0919 2.15869 12.5 2.15869Z" fill="#16916C"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.8685 15.7231L10.2293 15.0838L16.4237 8.86688C16.6355 8.65505 16.9789 8.65505 17.1907 8.86688L17.4464 9.12256C17.6583 9.3344 17.6583 9.67783 17.4464 9.88967L11.6356 15.7231C11.4238 15.9349 11.0804 15.9349 10.8685 15.7231Z" fill="white"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.2737 15.0839L11.6345 15.7231C11.4226 15.935 11.0792 15.935 10.8674 15.7231L8.32199 13.1777C8.11016 12.9659 8.11016 12.6225 8.32199 12.4106L8.57767 12.155C8.7895 11.9431 9.13294 11.9431 9.34477 12.155L12.2737 15.0839Z" fill="white"/>
                                    </svg>

                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};