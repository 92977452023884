// @flow
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";


export function Faq() {
    return (
        <div className="Ask__Question">
            <Accordion className="Accordion" color="white">
                <AccordionSummary
                    expandIcon={<div><svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25.4876" cy="25.9937" r="25.3309" fill="white" fillOpacity="0.05"/>
                        <path d="M17.231 22.884L25.4875 31.1033L33.7441 22.884" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </div>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography color="white" className="headerTitle">Who are you? What do you do?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="details">
                        We are a group of analysts and sports enthusiasts who use data science prediction<br/>
                        models based on 1000's of historical data points to make our clients profit in sports <br />
                        betting. We don’t make picks based on bias. All of our system plays are solely <br/>
                        statistical and analytical-based to give our clients an edge over time. Our clients <br/>
                        don’t gamble on games, they invest in our models, which are proven to win over <br/>time.

                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="Accordion" color="white">
                <AccordionSummary
                    expandIcon={<div><svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25.4876" cy="25.9937" r="25.3309" fill="white" fillOpacity="0.05"/>
                        <path d="M17.231 22.884L25.4875 31.1033L33.7441 22.884" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </div>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography color="white" className="headerTitle">Which models do you provide?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="details">
                        We currently offer prediction models in MLB, NFL, NHL, and NBA leagues, as well as<br/>
                        an NRFI (No Runs First Inning) model for MLB!  <br />
                        Each model covers the big 3 lines: Money Lines, Spreads, and Over/Unders
                        <br/>
                        In our upcoming roadmap, we plan to launch Fantasy sports lineups and multiple <br/>
                        soccer leagues!
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="Accordion" color="white">
                <AccordionSummary
                    expandIcon={<div><svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25.4876" cy="25.9937" r="25.3309" fill="white" fillOpacity="0.05"/>
                        <path d="M17.231 22.884L25.4875 31.1033L33.7441 22.884" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </div>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography color="white" className="headerTitle">
                        How many picks do you make per day?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="details">
                        There is no set amount each day. It depends on how many games are being played  <br/>that day.

                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="Accordion" color="white">
                <AccordionSummary
                    expandIcon={<div><svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25.4876" cy="25.9937" r="25.3309" fill="white" fillOpacity="0.05"/>
                        <path d="M17.231 22.884L25.4875 31.1033L33.7441 22.884" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </div>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography color="white" className="headerTitle">
                        When and how are picks sent out?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="details">
                        The prediction model/algorithm sends out plays about 1 hour before games start--<br/>
                        enough time for you to place bets. We send out early bets for users to do research,<br/>
                        but send out final pushes an hour before game times to account for any lineup <br/>changes.

                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="Accordion" color="white">
                <AccordionSummary
                    expandIcon={<div><svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25.4876" cy="25.9937" r="25.3309" fill="white" fillOpacity="0.05"/>
                        <path d="M17.231 22.884L25.4875 31.1033L33.7441 22.884" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </div>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography color="white" className="headerTitle"> Can you guarantee wins? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="details">
                        In basic predictions, no. All we can guarantee is that our models will help you make  <br/>
                        more informed wagers, based on data points which are relevant. <br/>
                        However, we are working on a very cool arbitrage feature where our bot searches  <br/>
                        the top arbitrage opportunities within books in each state to help you guarantee <br/>
                        wins, no matter the outcome of the game. We make this very simple and do all of  <br/>
                        the work so that you don't have to!
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="Accordion" color="white">
                <AccordionSummary
                    expandIcon={<div><svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25.4876" cy="25.9937" r="25.3309" fill="white" fillOpacity="0.05"/>
                        <path d="M17.231 22.884L25.4875 31.1033L33.7441 22.884" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </div>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography color="white" className="headerTitle"> What about player props?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="details">
                        While we work on building prediction models for player props, we offer a props.cash <br/>
                        bot in the site! That's a $19.99/month value by itself and you get it free!
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};